export default {
    links:[
        {
            name:'Dashboard',
            to:'/',
            class:'fa-tachometer-alt',
        },
        {
            name:'Content',
        },
        {
            name:'Playlist',
            to:'/playlist',
            class:'fa-film',
        },
        {
            name:'Songs',
            to:'/song',
            class:'fa-film',
        },
        {
            name:'Genre',
            to:'/genre',
            class:'fa-tree-city',
        },
        {
            name:'Vibes',
        },
        {
            name:'Medley',
            to:'/medley',
            class:'fa-tree-city',
        },

        {
            name:'Eternal',
            to:'/eternal',
            class:'fa-tree-city',
        },
        {
            name:'Settings',
        },
        {
            name:'Users',
            class:'fa-users',
            children:[
                {
                    name:'Administrators',
                    to:'/user/admin',
                    class:'fa-user-shield',
                },
                {
                    name:'Listeners',
                    to:'/user/listener',
                    class:'fa-users',
                },
            ]
        },
        {
            name:'Role',
            to:'/role',
            class:'fa-user-shield',
        },
        {
            name:'Country',
            to:'/country',
            class:'fa-map',
        },
        {
            name:'Site',
        },
        {
            name:'Page',
            to:'/page',
            class:'fa-user-shield',
        },
        {
            name:'Settings',
            to:'/setting',
            class:'fa-cogs',
        },
    ],
}
