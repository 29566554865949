<template>
	<!-- Footer -->
	<footer id="page-footer" class="bg-body-light">
		<div class="content py-3">
			<div class="row fs-sm">
				<div class="col-sm-6 order-sm-2 py-1 text-center text-sm-end">
					Crafted with <i class="fa fa-heart text-danger"></i> by <a class="fw-semibold" href="https://weliora.com" target="_blank">Weliora Kenya LTD</a>
				</div>
				<div class="col-sm-6 order-sm-1 py-1 text-center text-sm-start">
					<router-link class="fw-semibold" to="/" target="_blank">Vendor</router-link>
				</div>
			</div>
		</div>
	</footer>
	<!-- END Footer -->
</template>

<script>
export default {
  name: "Footer.vue"
}
</script>

<style scoped>

</style>
