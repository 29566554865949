export const TOGGLE_DARK_MODE = (context) => {
    context.commit("TOGGLE_DARK_MODE")
}

export const TOGGLE_SHOW_SIDEBAR = (context) => {
    context.commit("TOGGLE_SHOW_SIDEBAR")
}

export const TOGGLE_SIDEBAR_MINI = (context) => {
    context.commit("TOGGLE_SIDEBAR_MINI")
}

export const TOGGLE_SHOW_SIDE_OVERLAY = (context) => {
    context.commit("TOGGLE_SHOW_SIDE_OVERLAY")
}

export const SHOW_SIDEBAR_MINI = (context) => {
    context.commit("SHOW_SIDEBAR_MINI")
}
