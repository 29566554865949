<template>
    <div :class="['modal hide', show_confirm_dialog === true ? 'show d-block' :'']" id="modal-block-small" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">>
        <div class="modal-dialog modal-dialog-centered modal-sm" role="document">
            <div class="modal-content">
                <div class="block mb-0">
                    <div class="block-content fs-sm">
                        <p>Are you sure you want to delete that item?</p>
                    </div>
                    <div class="block-header block-header-default d-flex justify-content-between">
                        <button type="button" @click.prevent="hide" class="btn btn-sm btn-primary"><i class="fa fa-fw fa-cancel"></i> No</button>
                        <button type="button" class="btn btn-sm btn-danger"><i class="fa fa-fw fa-trash"></i> Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
    name: "ConfirmModal",
    data() {
        return {
            show_confirm_dialog:false
        }
    },
    methods:{
        ...mapActions(['HIDE_CONFIRM']),
        hide(){
            this.HIDE_CONFIRM()
        }
    },
    watch: {
        SHOW_CONFIRM_DIALOG(){
            this.show_confirm_dialog = this.SHOW_CONFIRM_DIALOG
        }
    },
    computed: {
        ...mapGetters(['SHOW_CONFIRM_DIALOG'])
    }
}
</script>
