import {createStore} from 'vuex'

import state from './state'
import * as mutations from './mutations'
import * as actions from './actions'
import * as getters from './getters'

import admin from "@/store/modules/admin";
import auth from "@/store/modules/auth";
import theme from "@/store/modules/theme";
import menu from "@/store/modules/menu";
import playlist from "@/store/modules/playlist";
import player from "@/store/modules/player";
import song from "@/store/modules/song";
import genre from "@/store/modules/genre";
import medley from "@/store/modules/medley";
import playlist_media from "@/store/modules/playlist_media";

export default createStore({
	state,
	actions,
	mutations,
	getters,
	modules: {
		admin,
		auth,
		theme,
		menu,
		playlist,
		player,
		song,
		genre,
		medley,
		playlist_media,
	}
})
