export const FETCH_GENRES = (context) => {
    return window.axios('/genre')
}
export const FETCH_GENRE = (context, params) => {
    return window.axios('/genre/'+params.genre_id)
}
export const CREATE_GENRE = (context, payload) => {
    return window.axios.post('/genre', payload)
}
export const UPDATE_GENRE = (context, params) => {
    return window.axios.put('/genre/'+params.genre_id, params.form_data)
}
export const DELETE_GENRE = (context, payload) => {
    return window.axios.post('/genre/delete', payload)
}
