export const ADD_SONG = (state, song) => {
    state.queue.push(song)
    localStorage.setItem('queue', JSON.stringify(state.queue));
}
export const SET_PLAYLIST = (state, queue) => {
    state.queue = queue
}
export const EMPTY_PLAYLIST = (state, song) => {
    state.queue = []
}

export const PLAY = (state) => {
    state.audio.play()
        .then(()=>{
            state.playing = true
        })
        .catch(e=>{})
}

export const PAUSE = (state) => {
    state.playing = false
    state.audio.pause()
}

export const STOP = (state) => {
    state.audio.pause()
    state.playing = false
    state.audio.currentTime = 0
}

export const NEXT = (state) => {
    let next_index = parseInt(state.current_index) + 1

    if(next_index > (state.queue.length - 1)){
        next_index = 0
    }

    state.current_index = next_index

    localStorage.setItem('current_index', state.current_index);
}

export const PREVIOUS = (state) => {
    let previous_index = parseInt(state.current_index) - 1

    if(previous_index < 0){
        previous_index = state.queue.length - 1
    }

    state.current_index = previous_index

    localStorage.setItem('current_index', state.current_index);
}

export const SET_SONG_INDEX = (state, song_index) => {
    state.current_index = song_index
    state.song = state.queue[song_index]
}

export const SET_SONG = (state, song_index = null) => {

    if(song_index !== null){
        state.current_index = song_index
        localStorage.setItem('current_index', state.current_index);
    }

    state.song = state.queue[state.current_index]
    state.audio.src = state.song.audio
}

export const PLAY_CURRENT_INDEX = (state, song_index) => {
    state.current_index = song_index
    state.song = state.queue[song_index]
    state.audio.src = state.song.audio
}

export const SET_PERCENTAGE_PLAYED = (state, percentage_played) => {
    state.percentage_played = percentage_played
}

export const SET_TOTAL_DURATION = (state, total_duration) => {
    state.total_duration = total_duration
}

export const SET_CURRENT_TIME = (state, new_time = null) => {
    if (new_time != null) {
        state.audio.currentTime = new_time
    }
    state.current_time = state.audio.currentTime
    state.percentage_played = (state.current_time / state.total_duration) * 100
}

export const SET_VOLUME = (state, volume) => {
    state.volume = volume
    state.audio.volume = state.volume / 100
    localStorage.setItem('volume', state.volume);
}
