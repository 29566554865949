import {SET_SONG_INDEX} from "@/store/modules/song/mutations";

export const FETCH_SONGS = ({state, commit}, params) => {
    // return window.axios('/song',{params:params.get_params})
    window.axios('/song',{params:params.get_params}).then( response => {
        commit('SET_SONGS', response.data['songs'])
    })
}
export const FETCH_SONG = (context, params) => {
    return window.axios('/song/'+params.song_id)
}
export const CREATE_SONG = (context, params) => {
    return window.axios.post('/song',params.form_data,{
        headers: {"Content-Type": "multipart/form-data"},
        onUploadProgress:params.upload_progress,
    })
}
export const UPDATE_SONG = (context, params) => {
    return window.axios.post('/song/'+params.song_id, params.form_data,{
        headers: {"Content-Type": "multipart/form-data"},
        onUploadProgress:params.upload_progress,
    })
}
export const DELETE_SONG = (context, dispatch, payload) => {
    return window.axios.post('/song/delete', payload)
}

