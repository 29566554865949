<template>
    <div id="page-loader" :class="[loading ? 'show': '']" class=""></div>
</template>
<script>
import {mapGetters} from 'vuex'
export default {
    data(){
        return {
            progress_count:0,
            loading:false
        }
    },
    watch:{
        LOADING(){
            this.loading = this.LOADING
        }
    },
    computed:{
        ...mapGetters(['LOADING'])
    }
}
</script>
