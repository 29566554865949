export const FETCH_PLAYLISTS = (context, params) => {
    return window.axios('/playlist',{
        params:params.get_params
    })
}
export const FETCH_PLAYLIST = (context, params) => {
    return window.axios('/playlist/'+params.playlist_id)
}
export const CREATE_PLAYLIST = (context, params) => {
    return window.axios.post('/playlist', params.form_data,{
        headers: {"Content-Type": "multipart/form-data"},
        onUploadProgress:params.upload_progress,
    })
}
export const UPDATE_PLAYLIST = (context, params) => {
    return window.axios.post('/playlist/'+params.playlist_id, params.form_data,{
        headers: {"Content-Type": "multipart/form-data"},
        onUploadProgress:params.upload_progress,
    })
}
export const DELETE_PLAYLIST = (context, params) => {
    return window.axios.delete('/playlist/'+params.playlist_id)
}
