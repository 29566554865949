<template>
	<div class="toast-container position-fixed p-3 space-y-3">

		<div :class="['toast show align-items-center border-0', message.type ? 'text-bg-'+message.type : 'text-bg-info']" v-for="(message, index) in messages" :key="index" role="alert" aria-live="assertive" aria-atomic="true" data-bs-autohide="true">
			<div class="d-flex">
				<div class="toast-body">
					{{ message.text}}
				</div>
				<button type="button" @click.prevent="delete_message(index)" class="btn-close btn-close-white me-2 m-auto" aria-label="Close"></button>
			</div>
		</div>

	</div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
export default {
	name: "BSToast",
	data() {
		return {
			messages:[],
		}
	},
	methods:{
		...mapActions(['REMOVE_MESSAGE']),
		delete_message(message_index){
			this.REMOVE_MESSAGE(message_index)
		}
	},
	watch: {
		MESSAGES(){
			this.messages = this.MESSAGES
		}
	},
	computed: {
		...mapGetters(['MESSAGES'])
	}
}
</script>

<style scoped>

</style>
