<template>
	<div class="bg-flat-darker py-3" style="background-color: white; position: fixed; bottom: 0; left: 0; width: 100%; max-width: 100%; z-index: 99999999;">

		<div class="controls d-flex justify-content-between h-100 align-items-center space-x-3">

			<div class="d-flex justify-content-between align-items-center space-x-3">
				<div @click.prevent="previous" class="previous rounded-circle d-flex align-items-center" style="height: 40px; width: 40px;">
					<button class="btn border-0 w-100 h-100 text-white"><i class="fa fa-fw fa-step-backward"></i></button>
				</div>
				<div @click.prevent="play" class="play rounded-circle bg-white d-flex align-items-center" style="height: 45px; width: 45px;">
					<button v-if="playing" class="btn border-0 w-100 h-100 text-secondary"><i class="fa fa-fw fa-pause"></i></button>
					<button v-else class="btn border-0 w-100 h-100 text-secondary"><i class="fa fa-fw fa-play"></i></button>
				</div>
				<div @click.prevent="next" class="next rounded-circle d-flex align-items-center" style="height: 40px; width: 40px;">
					<button class="btn border-0 w-100 h-100 text-white"><i class="fa fa-fw fa-step-forward"></i></button>
				</div>
			</div>

			<div class="seek text-white px-5">
				<div class="d-flex justify-content-between align-items-center">
					<small class="me-3">{{ $filters.human_readable_time(current_time) }}</small>
					<input type="range" class="form-range w-75" step="1" @change.prevent="change_time" v-model="current_time" min="0" :max="total_duration">
					<small class="ms-3">{{ $filters.human_readable_time(total_duration) }}</small>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import Modal from "@/components/Modal.vue";
import {Bootstrap5Pagination} from 'laravel-vue-pagination';

export default {
	components: {Bootstrap5Pagination, Modal},
	data() {
		return {
			errors: [],
			playing: false,
			current_time: 0,
			total_duration: 0,
			percentage_played: 0,
			per_page: 80,
			songs: [],
			queue:[],
			current_song: {
				id: null,
				name: "Select song"
			},
			current_index: null
		}
	},
	methods: {
		...mapActions('song', ['FETCH_SONGS', 'CREATE_SONG', 'UPDATE_SONG']),
		...mapActions('player', ['ADD_SONG', 'INIT_SONG', 'PLAY_SONG', 'PLAY', 'PAUSE', 'STOP', 'NEXT', 'PREVIOUS', 'CHANGE_TIME', 'INIT_PLAYER']),
		play_song(song_index) {

			this.current_song = this.songs.data[song_index]

			this.ADD_SONG(this.current_song)
			this.INIT_SONG()
			this.PLAY_SONG()

		},
		fetch_songs() {
		this.FETCH_SONGS({
			get_params: {
				per_page: this.per_page
			}
		}).then((response) => {
			let songs = response.data.songs.data
			this.songs.forEach(song => {
				this.ADD_SONG(song)
			})
		})
		}
	},
	mounted() {

		let params = {
			get_params: {
				per_page: this.per_page
			}
		}

		this.FETCH_SONGS(params)
	},
	watch: {
		ERRORS() {
			this.errors = this.ERRORS
		},
		SONGS() {
			this.songs = this.SONGS
		},

	},
	computed: {
		...mapGetters(['ERRORS']),
		...mapGetters('song', ['SONGS']),
	}
}
</script>