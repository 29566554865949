<template>

	<div id="page-container" :class="[show_sidebar ? 'sidebar-o sidebar-o-xs': '', sidebar_mini ? 'sidebar-mini': '' , dark_mode ? 'dark-mode page-header-dark sidebar-dark' : '', show_side_overlay ? 'side-overlay-o':'', 'enable-page-overlay side-scroll page-header-fixed']">
		<div id="page-overlay" @click.prevent="toggle_side_overlay"></div>

		<Sidebar></Sidebar>
		<Preloader></Preloader>
		<Header></Header>

		<!-- Main Container -->
		<main id="main-container">
            <Confirm></Confirm>
			<Toast class="top-0 end-0"></Toast>
            <!-- Page Content -->
            <div class="content">
			    <router-view></router-view>
            </div>
            <!-- END Page Content -->
		</main>
		<!-- END Main Container -->
		<Footer></Footer>
	</div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import Preloader from "./Preloader.vue";
import Toast from "./Toast.vue";
import Header from "./Header.vue";
import Sidebar from "./Sidebar.vue";
import Footer from "./Footer.vue";
import Confirm from "./Confirm.vue";
import FooterPlayer from "./FooterPlayer.vue";
export default {
	name: "Main-Dashboard",
	components: {Sidebar, Header, Footer, Preloader, Toast, Confirm, FooterPlayer},
	data() {
		return {
			dark_mode: true,
			sidebar_mini: false,
			show_sidebar: true,
			show_side_overlay: false
		}
	},
	methods:{
		...mapActions('theme', ['TOGGLE_DARK_MODE', 'TOGGLE_SHOW_SIDE_OVERLAY']),
		...mapActions(['REMOVE_MESSAGE']),
		toggle_side_overlay(){
			this.TOGGLE_SHOW_SIDE_OVERLAY()
		},
	},
	watch: {
		DARK_MODE() {
			this.dark_mode = this.DARK_MODE
		},
		SIDEBAR_MINI() {
			this.sidebar_mini = this.SIDEBAR_MINI
		},
		SHOW_SIDEBAR() {
			this.show_sidebar = this.SHOW_SIDEBAR
		},
		SHOW_SIDE_OVERLAY() {
			this.show_side_overlay = this.SHOW_SIDE_OVERLAY
		}
	},
	computed: {
		...mapGetters('theme', ['DARK_MODE', 'SIDEBAR_MINI', 'SHOW_SIDEBAR', 'SHOW_SIDE_OVERLAY']),
	}
}
</script>

<style scoped>

</style>
