export const FETCH_PLAYLIST_MEDIA = (context, params) => {
    return window.axios('/playlist/'+params.playlist_id+'/media',{
        params:params.get_params
    })
}
export const FETCH_SONGS_NOT_IN_PLAYLIST = (context, params) => {
    return window.axios('/playlist/'+params.playlist_id+'/song-not-in',{
        params:params.get_params
    })
}
export const CREATE_PLAYLIST_MEDIA = (context, params) => {
    return window.axios.post('/playlist/'+params.playlist_id+'/media', params.form_data)
}
export const UPDATE_PLAYLIST_MEDIA = (context, params) => {
    return window.axios.put('/playlist/'+params.playlist_id+'/media/'+params.song_id, params.form_data)
}
export const DELETE_PLAYLIST_MEDIA = (context, params) => {
    return window.axios.delete('/playlist/'+params.playlist_id+'/media/'+params.playlist_song_id)
}
