export const SET_LOADING = (context, payload) => {
    context.commit('LOADING', payload)
}

export const EMPTY_MESSAGES = (context) => {
    context.commit('EMPTY_MESSAGES')
}

export const PUSH_MESSAGE = (context, message) => {
    context.commit('PUSH_MESSAGE', message)
}

export const REMOVE_MESSAGE = (context, index) =>{
    context.commit('REMOVE_MESSAGE', index)
}

export const SHOW_CONFIRM = (context) =>{
    context.commit('SHOW_CONFIRM')
}

export const HIDE_CONFIRM = (context) =>{
    context.commit('HIDE_CONFIRM')
}
