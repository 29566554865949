export const SET_VENDOR_STATUS = (state, status) => {
    state.vendor_status = status
}
export const LOADING = (state, payload) => {
    state.loading = payload
}
export const SET_TITLE = (state, title) => {
    state.title = title
}
export const SET_MESSAGES = (state, messages) => {
    state.messages = messages
}

export const EMPTY_MESSAGES = (state) => {
    state.messages = []
}

export const PUSH_MESSAGE = (state, message) => {
    state.messages.push(message)
}

export const REMOVE_MESSAGE = (state, index) => {
    state.messages.splice(index, 1)
}

export const SHOW_CONFIRM = (state) => {
    state.show_confirm_dialog = true
}

export const HIDE_CONFIRM = (state) => {
    state.show_confirm_dialog = false
}
