export default{
    app_name:'BASHVIBE CMS',
    loading:false,
    title:'Dashboard',
    errors:[],
    messages:[],
    success:{
        message:''
    },
    show_confirm_dialog:false,
}
