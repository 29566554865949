export const ADD_TO_PLAYLIST = (context, song) => {
    context.commit('ADD_SONG', song)
}

export const ADD_SONG = (context, song) => {

    let audio_file = song.files.find(file => file.file_type.id === 2);

    let playlist_song = {
        id: song.id,
        name: song.name,
        cover: null,
        audio:audio_file.url,
        type:{name: "Song"},
        artist:{name:"BASH VIBE"}
    }

    context.commit('ADD_SONG', playlist_song)
}

export const EMPTY_PLAYLIST = (context) => {
    context.commit('EMPTY_PLAYLIST')
}

export const INIT_SONG = ({state, commit, dispatch}, song_index = null) => {
    if(song_index === null){
        song_index = state.queue.length - 1
    }
    commit('SET_SONG', song_index)
}

export const INIT_PLAYER = ({commit, dispatch, state}) =>{

    let playlist = JSON.parse( localStorage.getItem('queue') )
    let current_index = localStorage.getItem('current_index')

    if(playlist !== null && current_index !== null){
        commit('SET_PLAYLIST', playlist)
        commit("SET_SONG", current_index)
    }

    let volume = localStorage.getItem('volume') || 20
    commit("SET_VOLUME", volume)


    state.audio.addEventListener('timeupdate', () => {
        state.current_time = state.audio.currentTime
        let percentage_played = (state.current_time / state.total_duration) * 100
        commit('SET_PERCENTAGE_PLAYED', percentage_played)
    })
    state.audio.addEventListener('loadedmetadata', () => {
        commit('SET_TOTAL_DURATION', state.audio.duration)
    })

    state.audio.addEventListener('ended', () => {
        dispatch('NEXT')
    })
}

export const PLAY_SONG = (context, index = null) => {

    if(index !== null){
        context.commit('SET_SONG',  index)
    }
    context.commit("PLAY")
}

export const PLAY = (context) => {
    context.commit('PLAY')
}

export const STOP = (context) => {
    context.commit("STOP")
}

export const PAUSE = (context) => {
    context.commit("PAUSE")
}

export const NEXT = (context) => {
    context.commit("STOP")
    context.commit("NEXT")
    context.commit("SET_SONG")
    context.commit("PLAY")
}

export const PREVIOUS = (context) => {
    context.commit('STOP')
    context.commit("PREVIOUS")
    context.commit("SET_SONG")
    context.commit("PLAY")
}

export const CHANGE_VOLUME = (context, volume) => {
    context.commit('SET_VOLUME', volume)
}

export const CHANGE_TIME = (context, new_time) => {
    context.commit('SET_CURRENT_TIME', new_time)
}
