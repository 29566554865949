export const FETCH_ADMINS = ({commit}, params) => {
    return window.axios('/admin');
}
export const FETCH_ADMIN = ({commit}, params) => {
    return window.axios('/admin/'+params.admin_id);
}

export const CREATE_ADMIN = ({commit}, params) => {
    return window.axios.post('/admin', params.form_data);
}

export const UPDATE_ADMIN = ({commit}, params) => {
    return window.axios.put('/admin/'+params.admin_id, params.form_data)
}
