export const DARK_MODE = (state) => {
    return state.dark_mode
}

export const SHOW_SIDEBAR = (state) => {
    return state.show_sidebar
}

export const SIDEBAR_MINI = (state) => {
    return state.sidebar_mini
}

export const SHOW_SIDE_OVERLAY = (state) => {
    return state.show_side_overlay
}