<template>
	<div class="modal fade" :id="name" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" :aria-labelledby="name+'Label'" aria-hidden="true">
		<div :class="['modal-dialog', size]">
			<div class="modal-content">
				<div class="block block-rounded block-transparent mb-0">
					<div class="block-header block-header-default">
						<h3 class="block-title">{{ title }}</h3>
						<div class="block-options">
							<button type="button" class="btn-block-option" data-bs-dismiss="modal" aria-label="Close">
								<i class="fa fa-fw fa-times"></i>
							</button>
						</div>
					</div>
					<slot></slot>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		name:{
			type: String,
			default() {
				return "ModalName";
			}
		},
		title:{
			type: String,
			default() {
				return "Modal Title";
			}
		},
		size:{
			type: String,
			default() {
				return "";
			}
		}
	},
	data(){
		return {
			modal:null,
		}
	},
}
</script>
