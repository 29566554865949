export default {
	songs: [],
	song:{},
	song_index: null,
	audio : new Audio(),
	playing:false,
	current_time:0,
	total_duration:0,
	percentage_played:0,
}
