export const TOGGLE_DARK_MODE = (state) => {
    state.dark_mode = !state.dark_mode
}

export const TOGGLE_SHOW_SIDEBAR = (state) => {
    state.show_sidebar = !state.show_sidebar
}

export const TOGGLE_SIDEBAR_MINI = (state) => {
    state.sidebar_mini = !state.sidebar_mini
}

export const TOGGLE_SHOW_SIDE_OVERLAY = (state) => {
    state.show_side_overlay = !state.show_side_overlay
    state.show_page_overlay = state.show_side_overlay
}

export const SHOW_SIDEBAR_MINI = (state) => {
    state.sidebar_mini = true
}
