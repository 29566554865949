<template>
    <div class="hero-static d-flex align-items-center">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
  name: "AuthBlank"
}
</script>

<style scoped>

</style>
