export default {
    queue: [],
    volume:20,
    song:{
        id:null,
        name: null,
        cover:null,
        audio:null,
        type:null,
        artist:null
    },
    audio : new Audio(),
    playing:false,
    current_index:null,
    current_time:0,
    total_duration:0,
    percentage_played:0,
}
