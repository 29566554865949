import { createApp } from 'vue'
import App from '@/App.vue'
import '@/registerServiceWorker'
import router from '@/router'
import axios from "@/axios";
import store from '@/store'
import * as bootstrap from "bootstrap";

window.axios = axios;
window.bootstrap = bootstrap;

import '@fortawesome/fontawesome-free/scss/fontawesome.scss'
import '@fortawesome/fontawesome-free/scss/solid.scss'

import '@/scss/main.scss'

let app = createApp(App)
app.use(store)
app.use(router)


app.config.globalProperties.$filters = {
	human_readable_time(value) {
		let hhmmss = new Date(value * 1000).toISOString().substr(11, 8)
		return (hhmmss.indexOf('00:') === 0) ? hhmmss.substr(3) : hhmmss
	},
	bytes_to_mbs(bytes, decimals = 2) {
		if (bytes === 0) {
			return '0 Bytes';
		}
		const k = 1024;
		const dm = decimals < 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
		const i = Math.floor(Math.log(bytes) / Math.log(k));
		return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
	},
	percentage(value){
		return value + '%'
	}
};



app.mount('#app')
