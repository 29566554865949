<template>
	<!-- Sidebar -->
	<!--
		Sidebar Mini Mode - Display Helper classes

		Adding 'smini-hide' class to an element will make it invisible (opacity: 0) when the sidebar is in mini mode
		Adding 'smini-show' class to an element will make it visible (opacity: 1) when the sidebar is in mini mode
			If you would like to disable the transition animation, make sure to also add the 'no-transition' class to your element

		Adding 'smini-hidden' to an element will hide it when the sidebar is in mini mode
		Adding 'smini-visible' to an element will show it (display: inline-block) only when the sidebar is in mini mode
		Adding 'smini-visible-block' to an element will show it (display: block) only when the sidebar is in mini mode
	-->
	<nav id="sidebar" aria-label="Main Navigation">
		<!-- Side Header -->
		<div class="content-header">
			<!-- Logo -->
			<router-link to="/" class="fw-semibold text-dual">
				<span class="smini-visible">
					<i class="fa fa-circle-notch text-primary"></i>
				</span>
				<span class="smini-hide fs-5 tracking-wider">{{ app_name }}</span>
			</router-link>
			<!-- END Logo -->

			<!-- Extra -->
			<div>
				<!-- Dark Mode -->
				<!-- Layout API, functionality initialized in Template._uiApiLayout() -->
				<button @click.prevent="toggle_dark_mode" type="button" class="btn btn-sm btn-alt-secondary" data-toggle="layout" data-action="dark_mode_toggle">
					<i class="far fa-moon"></i>
				</button>
				<!-- END Dark Mode -->

				<!-- Close Sidebar, Visible only on mobile screens -->
				<!-- Layout API, functionality initialized in Template._uiApiLayout() -->
				<a class="btn btn-sm btn-alt-secondary ms-1" @click.prevent="toggle_show_sidebar" data-toggle="layout" data-action="sidebar_close" href="">
					<i class="fa fa-fw fa-times"></i>
				</a>
				<!-- END Close Sidebar -->
			</div>
			<!-- END Extra -->
		</div>
		<!-- END Side Header -->

		<!-- Sidebar Scrolling -->
		<div class="js-sidebar-scroll">
			<!-- Side Navigation -->
			<div class="content-side">
				<ul class="nav-main">

					<li v-for="link in LINKS" :key="link.name" :class="[ link.class ? 'nav-main-item' : 'nav-main-heading' ]" >

						<router-link v-if="link.class" :to="link.children ? '': link.to" :class="[link.class ? 'nav-main-link' :'', link.children ? 'nav-main-link-submenu' :'']" @click.prevent=" expand" data-toggle="submenu" aria-haspopup="true" aria-expanded="false">
							<i :class="'nav-main-link-icon fa fw '+link.class"></i>
							<span class="nav-main-link-name">{{ link.name }}</span>
						</router-link>

						<span v-else>{{ link.name }}</span>

						<ul class="nav-main-submenu" v-if="link.children">
							<li class="nav-main-item" v-for="submenu_link in link.children" :key="submenu_link.name">
								<router-link :to="submenu_link.to" class="nav-main-link">
									<span class="nav-main-link-name">{{ submenu_link.name }}</span>
								</router-link>
							</li>
						</ul>
					</li>
				</ul>
			</div>
			<!-- END Side Navigation -->
		</div>
		<!-- END Sidebar Scrolling -->
	</nav>
	<!-- END Sidebar -->
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
export default {
	data(){
		return{
            app_name:'BASH VIBE',
			links:[],
            admin:{},
			dark_mode: true
		}
	},
	methods:{
		...mapActions('theme', ['TOGGLE_DARK_MODE', 'TOGGLE_SHOW_SIDEBAR', 'TOGGLE_SHOW_SIDE_OVERLAY']),
		toggle_dark_mode(){
			this.TOGGLE_DARK_MODE()
		},
		toggle_show_sidebar(){
			this.TOGGLE_SHOW_SIDEBAR()
		},
		toggle_side_overlay(){
			this.TOGGLE_SHOW_SIDE_OVERLAY()
		},
		expand(event){
			let link = event.target;
			let mainNav = link.closest('.nav-main');
			if (!(
					(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) > 991
					&& mainNav.classList.contains('nav-main-horizontal')
					&& mainNav.classList.contains('nav-main-hover')
				)) {
				let parentLi = link.closest('li');
				if (parentLi.classList.contains('open')) {
					parentLi.classList.remove('open');
					link.setAttribute('aria-expanded', 'false');
				} else {
					Array.from(link.closest('ul').children).forEach(el => {
						el.classList.remove('open');
					});
					parentLi.classList.add('open');
					link.setAttribute('aria-expanded', 'true');
				}
			}

			return false;
		},
		sidebar(){
			var links = document.querySelectorAll('[data-toggle="submenu"]');

			if (links) {
				links.forEach(link => {
					link.addEventListener('click', e => {
						e.preventDefault();

						// Get main navigation
						let mainNav = link.closest('.nav-main');

						// Check if we are in horizontal navigation, large screen and hover is enabled
						if (
							!(
								(window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) > 991
								&& mainNav.classList.contains('nav-main-horizontal')
								&& mainNav.classList.contains('nav-main-hover')
							)
						) {
							// Get link's parent
							let parentLi = link.closest('li');

							if (parentLi.classList.contains('open')) {
								// If submenu is open, close it..
								parentLi.classList.remove('open');

								link.setAttribute('aria-expanded', 'false');
							} else {
								// .. else if submenu is closed, close all other (same level) submenus first before open it
								Array.from(link.closest('ul').children).forEach(el => {
									el.classList.remove('open');
								});

								parentLi.classList.add('open');

								link.setAttribute('aria-expanded', 'true');
							}
						}

						return false;
					});
				});
			}
		}
	},
    watch: {
	    LINKS(){
		    this.links = this.LINKS
	    },
        ADMIN(){
            this.admin = this.ADMIN
        },
    },
	computed: {
		...mapGetters('admin',['ADMIN']),
		...mapGetters('menu',['LINKS']),
	},

}
</script>
